import React from 'react'
import './CSS/NavigationBar.css'


const NavigationBar = () =>{
    return(
        <nav className="navbar">
        <h1>Welcome</h1>
        <div className="links">
        <a href="/home">Home</a>
        <a href="/logoutbutton" style={{ 
            color: 'white', 
            backgroundColor: '#f1356d',
            borderRadius: '8px' 
          }}>Logout</a>
        </div>
      </nav>
    );
}

export default NavigationBar;