import React from 'react'

const HealthScreenThankyouPage = () =>{
    return(
        <div>
            <h3>
                Thank you for completing your Health Screener. This is now with our health team for review
            </h3><br/>
            <p>There are three possible outcome of this review</p><br/>
            <p>1. Your Health screener is approved and your exploration call is immediately confirmed. Please Check your email.</p><br/>
            <p>2. We will request some additional information from you. This may be over email or in the form of a breief call.</p><br/>
            <p>3. If we don't feel as though we can support you safely through your psilocybin experience, or we belive thaking a psychedelic is dangerous for you, we may decline your applicaton and therefore your exploration call. If this happens, we will send you a personal email with further deatils</p><br/>
        </div>
    )
}

export default HealthScreenThankyouPage