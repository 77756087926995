import { useEffect } from 'react'
import Steps from './Steps';

const SendDataToBackend =(inputValues) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(inputValues.parentToChild)
  };
  console.log(requestOptions);
  useEffect(() =>{
    fetch('https://backend.journey.synthesisretreat.com/api/form-submission',requestOptions)
    .then(response => response.json())
        .catch(e => {
          console.log(e);
        });  
      },[])
        return null
}
      export default SendDataToBackend
      