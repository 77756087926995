import './App.css';
import React from 'react';
import LoginButton from './Components/LoginButton';
import './Components/CSS/Buttons.css'
import { useAuth0 } from '@auth0/auth0-react';
import NavigationBar from './Components/NavigationBar';
import Steps from './Components/Steps';
import './Components/CSS/Home.css'
import RenderContent from './Components/RenderContent';
import {useState,useEffect} from 'react';


function App() {
  const { isAuthenticated, user} = useAuth0()
  if(user){
    console.log(user);
  var _hsq = window._hsq = window._hsq || [];
_hsq.push(["identify",{
    email : user.email
}]);
  }
 return (
    <div className="App">
    {!isAuthenticated && <LoginButton/>}
    {isAuthenticated && <NavigationBar/> }
    {isAuthenticated && <Steps dealId={"8117338","2"}/> }
    {isAuthenticated && <RenderContent/>}
    </div>
  );
}

export default App;
