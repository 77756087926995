import React from "react";
import { BrowserRouter, Switch,Route} from "react-router-dom";
import LogoutButton from "./LogoutButton";
import PaymentSuccess from "./PaymentSuccess";
import Welcome from "./Welcome";
import  {useState} from 'react'
import { useAuth0 } from "@auth0/auth0-react";
import AutoScreen from "./AutoScreen";
import NotQualified from "./NotQualified";


const RenderContent = () => {
  const [dealStage,setDealStage] = useState();
  const [autoScreen,setAutoScreen] = useState()
  console.log(autoScreen);
  const { user } = useAuth0()
  if(user !== undefined){
    let url = "https://backend.journey.synthesisretreat.com/customer/"+user.email;
    console.log(url)
      fetch(url)
      .then(response =>  response.json())
      .then(data => (setDealStage(data.dealStage),setAutoScreen(data.autoScreenResults)))
    }
 console.log(dealStage);
    return(
        <div className ="render-content">
          <BrowserRouter>
          <Switch>
          {<Route path ="/success"  component={PaymentSuccess}/>}
          { (dealStage === "8124511") &&<Route path ="/" component={PaymentSuccess}/>}
          { (dealStage === "8117338" || !dealStage)&&<Route path ="/" component={Welcome}/>}
          {(dealStage === "8124510" && autoScreen === "0") && <Route path ="/" component={AutoScreen}/> }
          {(dealStage === "8124510" && autoScreen === "1") && <Route path ="/" component={NotQualified}/> }
          <Route path="/logoutbutton" component={LogoutButton}/>
          </Switch>
          </BrowserRouter>
        {/* <BrowserRouter>
                <Switch>
                <Route path ="/success" component={PaymentSuccess}/>
                <Route path="/logoutbutton" component={LogoutButton}/>
                <Route path="/" component={Welcome}/>
                </Switch>
        </BrowserRouter> */}
        </div>
    )
}
export default RenderContent;