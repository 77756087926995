import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Auth0Provider} from '@auth0/auth0-react'
const domain = "synthesis-institute.eu.auth0.com";
const clientId = "A5DoLcqtcApGXHGxsvdI4LhLf5M4CfZu";

ReactDOM.render(
    <Auth0Provider
    domain = {domain}
    clientId={clientId}
    redirectUri={window.location.origin}>
    <App />
    </Auth0Provider>,
  document.getElementById('root')
);


