import React from 'react'
import { useState,useEffect } from 'react'
import Questionnaire from './Questionnaire';
import { useAuth0 } from '@auth0/auth0-react';
import DealCreation from './DealCreation';
import Steps from './Steps';
const Welcome = () => {
    const {user} = useAuth0();
    const[data,setData]= useState();
    // useEffect(()=>{
    //     var params = {
    //         Bucket: "synthesis-customer-portal-data",
    //            Key: "step1-form.json"
    //            };
    //         AWS.config.update({accessKeyId: "AKIAY7INHIQKSA6CFJHC",
    //         secretAccessKey:"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",
    //         region: "eu-central-1"})
    //         const s3 = new AWS.S3()
    //         s3.getObject(params, function(err, data) {
    //             if (err) 
    //             console.log(err, err.stack); // an error occurred
    //             else 
    //             setData(data.Body.toString())});
    // },[]);
    const[welcomePage,setWelcomepage] = useState({1:true,2:false,3:false})
    const handleClick = () => {
            setWelcomepage({[1]:false});
            setWelcomepage({[2]:true})
        }
    const  questionStart = () => {
        DealCreation(user.email);
        setWelcomepage({[2]:false})
        setWelcomepage({[1]:false})
        setWelcomepage({[3]:true})
    }
  return(
    <div className="welcome-content">
    { welcomePage[1] && (<div>
    <h3> Step one : Retreat Enrolment Questionnaire</h3><br></br>
    <p>To ensure your safety, it is essential that you provide Synthesis with accurate information regarding your physical and mental health. Failure to disclose or accurately report on any requested medical information and/or pertinent past experience can result in Synthesis withholding participation in a psilocybin-containing truffle ceremony without refund.</p><br></br>
    <p>In this Retreat Enrollment Form, and throughout the application process, you will be asked to provide sensitive personal information.</p><br></br>
    <p>Any data you provide through the Retreat Enrollment Form, Health Screening, and/or Retreat Assessment Session may be stored, processed and analyzed by Synthesis for the purpose of assessing your ability to participate in a psychedelic retreat.</p><br></br>
    <p>The application process is of vital importance as it enables Synthesis to assess whether a psychedelic retreat could be a meaningful experience for you, and allows us to prepare to support your needs once you’re approved to participate in a retreat.</p><br></br>
    <button className="start" onClick = {() => {handleClick()}}>Start</button>
    </div>) } 
    {welcomePage[2] && (
        <div>
            <h3>Please be advised</h3><br></br>
            <p>To Support Your Well Being:We may review your personal information before, during and after your retreat in order to support your physical and psychological well being.</p><br></br>
            <p>To Support the Improvement of Our Services:We may review your personal information and use it to improve the quality of our programs and services, as well as to deepen our understanding of psychedelic-assisted therapy and the efficacy of psychedelic retreats.</p><br></br>
            <p>Your data will be handled to the highest standards of confidentiality.</p><br></br>
            <a href="https://explore.synthesisretreat.com/data-safety?__hstc=233546881.42097bc299d9c279b21b3e8285d3fda4.1632129523141.1632237901541.1632324931800.9&__hssc=233546881.1.1632324931800&__hsfp=587836302&_ga=2.182813670.1362969178.1632324931-1566530174.1632136932" target="_blank" >Data Safety at Synthesis Explained</a><br></br>
            <p>Click to learn more on why, where, and how we process your data.</p><br></br>
            <button className="start" onClick = {() =>{questionStart()}}>Continue</button>
        </div>)}
    {welcomePage[3] && <Questionnaire /*parentToChild={JSON.parse(data)}*/ />}
     </div>
    );
}
export default Welcome