import React from "react";
import { useState } from "react";
import Healthscreener from "./HealthScreener";
import { useAuth0 } from '@auth0/auth0-react';
import PaymentDealupdate from "./PaymentDealupdate";
import Steps from "./Steps";
/* eslint-disable */
const PaymentSuccess =() => {
  const {user} = useAuth0();
  // var {value} = useParams();
  const [healthScreenerWelcomePage,setHeatlthScreenerWelcomePage] = useState(false);
  const [paymentConfirm,setPaymentConfirm] = useState(true);
  const [redirectToHealthScreener,setRedirectToHealthScreener] = useState(false);
  let [dealId,setDealID] = useState("8124511");
  let [autoScreen,setAutoScreen] = useState("0");
  PaymentDealupdate(user.email);
  return(
    <div class="payment-success">
      <Steps dealId = {dealId} autoScreen={autoScreen}/>
      {paymentConfirm && <div> <h3>Payment completed</h3><br/>
      <p>Thank you for completing your payment. We've sent you a confirmation email with the details of your transaction. Click the following button to proceed with the Health screener</p>
      <button className="start" onClick={()=>{setHeatlthScreenerWelcomePage(true),setPaymentConfirm(false)}}>Procced to Screener</button> </div>}
      {healthScreenerWelcomePage && 
      <div>
        <p>To ensure your safety, it is a requirement that we have accurate information regarding your physical and mental health. This information will be kept confidential. Failure to disclose any medical information will result in Synthesis withholding participation in the psilocybin-containing truffle ceremony without refund. Your safety is our highest priority.</p><br/>
        <p>By accepting, I certify that all information is true and correct to the best of my knowledge.</p><br/>
        <button className="start" onClick={()=>{setHeatlthScreenerWelcomePage(false),setRedirectToHealthScreener(true)}}>START SCREENER</button>
      </div>}
      {redirectToHealthScreener && <Healthscreener/>}
    </div>
  )
}
export default PaymentSuccess