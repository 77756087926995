import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';


const Payment =() => {
  const stripe_checkout = process.env.REACT_APP_STRIPE_CHECKOUT;
  const stripe_publicKet = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
  fetch(stripe_checkout)
  .then(response => {
        console.log(response);
        return response.json();
      })
  .then(async session => {
   const stripeSession = session.id;
    const stripe = await loadStripe(stripe_publicKet);
    stripe.redirectToCheckout({ sessionId: stripeSession });
  })
  .catch(e => {
    console.log(e);
  });  

}
export default Payment
