import React from 'react'
import { useState,useMemo } from 'react'
import './CSS/Form.css'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import NotQualified from './NotQualified'
 import { useAuth0 } from "@auth0/auth0-react";
import SendDataToBackend from './SendDataToBackend'
import AutoScreen from './AutoScreen'


/* eslint-disable */
const Questionnaire =() => {
//const data = formdata.parentToChild
const data ={ 
    "fields" : [
    {
        "id" : 1,
        "input_type" : "radio",
        "labels": [
            {
                "id" : 1,
                "label":"I agree"
            }],
        "question" :"I certify that all information I provide throughout the application process will be true and correct to the best of my knowledge*",
        "required" : true,
        "name": "question1",
        "breakpoint":false,
        "field_name" : "button"

    },
    {
        "id" : 2,
        "input_type" : "radio",
        "labels": [
            {
                "id" : 1,
                "label":"I agree"
            }],
        "question" :"I accept that all information I provide through the Retreat Enrollment Form, Health Screening, and Retreat Assessment Session may be stored, processed, and analyzed by Synthesis for the above-mentioned purposes*",
        "required" : true,
        "name": "question2",
        "breakpoint":false,
        "field_name" : "button"


    },
    {
        "id" : 3,
        "input_type" : "radio",
        "labels": [
            {
                "id" : 1,
                "label":"I agree"
            }],
        "question" :"I understand that if my application is approved and I complete my enrollment in a retreat, I cannot opt-out from data collection by Synthesis until I've completed the program. Once I've completed my participation in the retreat, I can submit a request for all my personal data to be removed from Synthesis storage*",
        "required" : true,
        "name": "question3",
        "breakpoint":false,
        "field_name" : "button"


    },
    {
      "id" : 4,
      "input_type" : "text",
      "question" : "Before we begin, please fill in your First name?*",
      "placeholder": "Please enter your first name",
      "required" : true,
      "name" : "question4",
      "breakpoint":false,
      "field_name" : "first_name"

    },
    {
        "id" : 5,
        "input_type" : "text",
        "question" : "what is your last name?*",
        "placeholder": "Please enter your last name",
        "required" : true,
        "name" : "question5",
        "breakpoint":false,
        "field_name" : "last_name"

    },
    {
        "id" : 6,
        "input_type" : "select",
        "question" : "What country do you currently live in?*",
        "placeholder": "Please enter your email",
        "required" : true,
        "name" : "question7",
        "breakpoint":false,
        "field_name" : "country"

    },
    {
        "id" : 7,
        "input_type" : "radio",
        "labels": [
            {
                "id" : 1,
                "label":"Core (3-Day)"
            },
            {
                "id" : 2,
                "label":"Expansion (5-Day)"
            },
            {
                "id" : 3,
                "label":"I am currently unsure and would like to learn more during my assesment call"
            }
        ],
        "question" : "Which Wellness Retreat are you wishing to apply for?*",
        "placeholder": "Please enter your email",
        "required" : true,
        "name" : "question8",
        "breakpoint":false,
        "field_name" : "option"

    },
    {
        "id" : 8,
        "input_type" : "select",
        "options": [
            {
                "id" : 1,
                "label":"Enhanced creativity",
                "exit" : "No"
            },
            {
                "id" : 2,
                "label":"Process unresolved beliefs",
                "exit" : "No"
            },
            {
                "id" : 3,
                "label":"Overcome limiting beliefs",
                "exit" : "No"
            },
            {
                "id" : 4,
                "label":"Improved focus",
                "exit" : "No"
            },
            {
                "id" : 5,
                "label":"Have a mystical/spiritual experience",
                "exit" : "No"
            },
            {
                "id" : 6,
                "label":"Other",
                "exit" : "No"
            }
            
        ],
        "question" : "What do you hope to gain by participating in a psychedelic retreat?This question is required. *",
        "placeholder": "Please select an option",
        "required" : true,
        "name" : "question9",
        "breakpoint":false,
        "field_name" : "option"


    },
    {
        "id" : 9,
        "input_type" : "select",
        "options": [
            {
                "id" : 1,
                "label":"Yes",
                "exit" : "Yes"
            },
            {
                "id" : 2,
                "label":"No",
                "exit" : "No"
            }
        ],
        "question" : "Do you currently or have you ever suffered from drug and/or alcohol abuse (including, but not limited to: Morphine, ketamine, cocaine, heroine, amphetamines, GHB, opioids, painkillers and/or other prescription drugs)?*",
        "placeholder": "Please select an option",
        "required" : true,
        "name" : "question10",
        "breakpoint":true,
        "field_name" : "option"

    },
    {
        "id" : 10,
        "input_type" : "select",
        "options": [
            {
                "id" : 1,
                "label":"Suicidal Ideation",
                "exit" : "Yes"
            },
            {
                "id" : 2,
                "label":"Schizophrenia",
                "exit" : "Yes"
            },
            {
                "id" : 3,
                "label":"Psychosis",
                "exit" : "Yes"
            },
            {
                "id" : 4,
                "label":"A Personality Disorder(including, but not limited to: Dissociative identity Disorder, Schizoaffective Disorder,Mania or Hypomania)",
                "exit" : "Yes"
            },
            {
                "id" : 4,
                "label":"I've never been diagnosed with or suspected that I suffer from any of the conditions listed above.",
                "exit" : "No"
            }

        ],
        "question" : "Have you ever been diagnosed with or have you ever suspected that you suffer from any of the conditions listed below?*",
        "placeholder": "Please select an option",
        "required" : true,
        "name" : "question11",
        "breakpoint":true,
        "field_name" : "option"

    },
    {
        "id" : 11,
        "input_type" : "select",
        "options": [
            {
                "id" : 1,
                "label":"Yes",
                "exit" : "Yes"
            },
            {
                "id" : 2,
                "label":"No",
                "exit" : "No"
            }
        ],
        "question" : "Do you currently or have you ever suffered from drug and/or alcohol abuse (including, but not limited to: Morphine, ketamine, cocaine, heroine, amphetamines, GHB, opioids, painkillers and/or other prescription drugs)?*",
        "placeholder": "Please select an option",
        "required" : true,
        "name" : "question12",
        "breakpoint":true,
        "field_name" : "option"

    },
    {
        "id" : 12,
        "input_type" : "select",
        "options": [
            {
                "id" : 1,
                "label":"Yes",
                "exit" : "Yes"
            },
            {
                "id" : 2,
                "label":"No",
                "exit" : "No"
            }
        ],
        "question" : "Within the last five years have you experienced psychiatric hospitalization?*",
        "placeholder": "Please select an option",
        "required" : true,
        "name" : "question13",
        "breakpoint":true,
        "field_name" : "option"

    },
    {
        "id" : 13,
        "input_type" : "select",
        "options": [
            {
                "id" : 1,
                "label":"Antidepressant(including,but not limited to:selective serotonin reuptake inhibitors(SSRI's)).",
                "exit" : "Yes"
            },
            {
                "id" : 2,
                "label":"Mood Stabilizer(including,but not limited to: Lithium and valproate).",
                "exit" : "Yes"
            },
            {
                "id" : 3,
                "label":"I'm not currently taking or within the last 12-months have not taken any of the medications listed above.",
                "exit" : "No"
            }
        ],
        "question" : "Within the last five years have you experienced psychiatric hospitalization?*",
        "placeholder": "Please select an option",
        "required" : true,
        "name" : "question14",
        "breakpoint":true,
        "field_name" : "option"

    }
    ]
}
const { user } = useAuth0();
const[question,setQuestion] = useState({1:true})
const [inputValues, setInputValues] = useState({"hutk":__hsUserToken,"emailId":user.email})
const[questions,setQuestions] =useState(true)
const [sorry,setSorrypage] = useState(false);
const [payment,setPaymentpage] = useState(false);
const [callStripe,setCallStripr] = useState(false);
const [errorMsg,setErrorMessage] = useState("");
const [welcomePaymentpage,setWelcomepage] = useState(false)
const [checked,setChecked] = useState(false)
const [submitHandle,setHandleSubmit] = useState(false)


const handleQuestionnaireClick = (event,nextQuestionnumber,currentquesitonnumner,value) =>{
    console.log(value);
    if(value=== '' || !value)
    {
        setQuestion({[nextQuestionnumber]: false})
        setErrorMessage(true)
        setQuestion({[currentquesitonnumner]: true});
        
    }else{
    for(var i=1;i<=nextQuestionnumber;i++){
        if(i===nextQuestionnumber){
            setQuestion({[i]:true})
        }
        else
        setQuestion({[i]:false})
    }
    event.preventDefault();
    }
}
const options = useMemo(() => countryList().getData(), [])
const handleChange = (name,value) => {
    console.log(name)
    console.log(value)
    setErrorMessage(false);
    setInputValues({
      ...inputValues,
      [name]: value
    })  
}

const handleSubmit = (event) =>{
    event.preventDefault();
}
console.log(inputValues)
    return(
        <div className="questionnarie-form">
            <form onSubmit={(event) =>{handleSubmit(event)}}>
                {questions && data.fields.map(form => {
                    if(form.input_type === "text"){
                        if(question[form.id]){
                        return(
                        <div className="questions">
                        <label>{form.question}</label><br></br>
                        <input autoFocus className='form-control' id='question1'  placeholder={form.placeholder} value={ inputValues[form.name]} name={form.name} onChange={(e) =>{handleChange(e.target.name,e.target.value)}} onKeyDown ={(event)=>{ event.key === 'Enter' ? ((!inputValues[form.name] ? (event.preventDefault(),event.stopPropagation()) : ' ' ),handleQuestionnaireClick(event,form.id+1,form.id,inputValues[form.name])): ' '}}></input> <br></br>
                        <div><br/>
                        {(form.id !==1) &&  <button className="question-button" onClick={(event)=>{handleQuestionnaireClick(event,form.id-1,form.id,"validation"),setErrorMessage(false)}}>Back</button> }
                        {  (form.id !==1) && <div className="divider"/>}
                        <button className="question-button"  onClick={(event)=>{handleQuestionnaireClick(event,form.id+1,form.id,inputValues[form.name])}} >OK</button>
                        {errorMsg && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                        </div>
                        </div>
                        )
                    }}
                    if(form.input_type === "radio"){
                        if(question[form.id]){
                        return(
                        <div className="questions">
                        <label>{form.question}</label><br></br>
                         {form.labels.map(fields =>{
                            return(<div className ='radio-toolbar'>
                                <input type="radio" autoFocus id={fields.id} name={form.name} value={fields.label} checked={inputValues[form.name] === fields.label} onChange={(e) =>{console.log(e),handleChange(e.target.name,e.target.value)}} onKeyDown ={(event)=>{ event.key === 'Enter' ? ((!inputValues[form.name] ? (event.preventDefault(),event.stopPropagation()) : ' ' ),handleQuestionnaireClick(event,form.id+1,form.id,inputValues[form.name])): ' '}}></input>&nbsp;
                                <label for={fields.id}>{fields.label}</label>
                            </div>)
                         })}
                        <div>
                        {(form.id !==1) &&  <button className="question-button" onClick={(event)=>{handleQuestionnaireClick(event,form.id-1,form.id,"validation")}}>Back</button> }
                        {  (form.id !==1) && <div className="divider"/>}
                        <button className="question-button"  onClick={(event)=>{handleQuestionnaireClick(event,form.id+1,form.id,inputValues[form.name])}} >OK</button>
                        {errorMsg && <div>
                       <span style={{color: "red"}}>Please Select the answer </span>
                       </div>}
                        </div>                      
                        </div>
                        )
                    }}
                    if(form.input_type === "select"){
                        if(question[form.id]){
                        return(
                        <div className="questions">
                        <label>{form.question}</label><br></br>
                        <Select autoFocus options={(form.field_name === 'country') ? options : form.options} value={inputValues[form.name]} onChange={(value) =>{handleChange(form.name,value)}} onKeyDown ={(event)=>{
                        event.key === 'Enter' 
                        ? (form.id !== 13 && ((form.breakpoint || form.breakpoint === false) && inputValues[form.name] && inputValues[form.name].exit === 'No') ||form.field_name === 'country' ) 
                        ? handleQuestionnaireClick(event,form.id+1,form.id,inputValues[form.name]) 
                        : ((form.id !== 13 || form.id === 13) && (form.breakpoint && inputValues[form.name] && inputValues[form.name].exit === 'Yes')) 
                        ? (handleChange("status","1"),setSorrypage(true),setQuestions(false),setHandleSubmit(true)) 
                        : (form.id === 13 && (form.breakpoint && inputValues[form.name] && inputValues[form.name].exit === 'No')) 
                        ? ((handleChange("status","0"),setPaymentpage(true),setSorrypage(false),setQuestions(false),setHandleSubmit(true))) :(event.preventDefault(),event.stopPropagation(),setErrorMessage(true)) : ' '}} />
                        <div>
                        {(form.id !==1) &&  <button className="question-button" onClick={(event)=>{handleQuestionnaireClick(event,form.id-1,form.id,"validation"),setErrorMessage(false) }}>Back</button> }
                        { (form.id !==1) && <div className="divider"/>}
                        {((form.breakpoint && inputValues[form.name] && inputValues[form.name].exit === 'Yes' ) || form.id === 13) ? <button className="question-button"  onClick={(event)=>{handleQuestionnaireClick(event,form.id+1,form.id,inputValues[form.name]),setHandleSubmit(true),form.id === 13 ? (handleChange("status","0"),setPaymentpage(true),setSorrypage(false)): (handleChange("status","1"),setSorrypage(true),setQuestions(false))}} >Submit</button>: <button className="question-button"  onClick={(event)=>{handleQuestionnaireClick(event,form.id+1,form.id,inputValues[form.name])}} >OK</button>}
                        {errorMsg && <div>
                       <span style={{color: "red"}}>Please Select the answer </span>
                       </div>}
                        </div>                   
                        </div>
                        )
                    }}
                })}
                
                {sorry && (<NotQualified parentToChild={(inputValues)} />) }
                {submitHandle && <SendDataToBackend parentToChild={(inputValues)}/>}
                {payment && <AutoScreen/>}
                {/* {payment && <div className="payment-content">
                    <h3>Thank you for completing the Retreat Enrollment Questionnaire</h3><br></br>
                    <p>We’re pleased to inform you that based on the answers provided, you’re eligible to proceed to step two of the application process.</p><br></br>
                    <p>As a reminder, step two of the application process is Health Screening and requires that you:</p><br></br>
                    <p>Submit the $1 retreat application fee*; Answer 75+ questions relating to your medical history, overall health and relevant personal experiences.</p><br></br>
                    <p>*This fee will be applied to your retreat tuition once your application is approved. If your application is declined, the fee will be fully refunded.</p><br></br>
                    <p>Are you ready to begin step two of the retreat application process? Simply click the link below to get started.</p><br></br>
                    <button class="start" onClick={() =>{setWelcomepage(true),setPaymentpage(false)}}>Continue to step 2</button></div>}
                {welcomePaymentpage && <div className="payment-content">
                    <h3>Retreat Application Process</h3>
                    <h3>Step Two: Health Screening Application Fee</h3><br></br>
                    <ul className="points">
                    <h3> $200 retreat application fee is required from all applicants. This fee is refundable according to the following terms and conditions:</h3>
                        <li>When an applicant is fully approved to participate in a psychedelic retreat, the $200 retreat application fee will be deducted from the retreat tuition total.</li>
                        <li>If an applicant is not approved to participate in a psychedelic retreat, the $200 retreat application fee will be refunded.</li>
                    </ul><br></br>
                    <ul className="points">
                    <h3>The application fee is not refundable if:</h3>
                        <li>An applicant does not complete and submit their Health Screening.</li>
                        <li>An applicant does not attend their pre-scheduled Assessment Session.</li>
                        <li>An applicant is found to have provided untruthful or misleading information at any time during the retreat application process.</li>
                    </ul><br></br>
                    <h3>I agree to the terms and conditions of the $200 retreat application fee as outlined above.</h3><br/>
                    <div>
                    <input type="checkbox" id="agree" name="agree"  checked={checked} onClick={()=>{setChecked(true)}}/>&nbsp;
                    <label htmlFor="agree">I agree*</label>
                    </div>
                    {checked && <button className="start" onClick={()=>{setCallStripr(true)}}>CLICK HERE TO PAY THE RETREAT APPLICATION FEE</button>}
                </div>
                }
                {callStripe && <Payment/>} */}
            </form>
        </div>
    );
}
export default Questionnaire
                
                {/* {question[1] &&<div className="questions">
                    <label>Before we begin, please fill in your email*</label><br></br>
                    <input className='form-control' id='question1' placeholder='Please enter your email' value={answers1} onChange={(e) => {setAnswer1(e.target.value)}}></input> <br></br>
                    <button className="question-button"  onClick={()=>handleClick(2)} disabled={!answers1} >OK</button>
                </div>}
                {question[2] &&<div className="questions">
                    <label>What country do you currently live in?</label><br></br>
                    <Select options={options} value={answers2} onChange={(value) => setAnswer2(value)} />
                    <div>
                    <button className="question-button" onClick={() =>handleClick(1)}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button" disabled={!answers2} onClick={() =>handleClick(3)}>OK</button> 
                    </div>
                </div>}
                { question[3] && <div className="questions">
                    <label>What are the dates of the retreat you'd like to participate in?*</label><br></br>
                    <input className='form-control' placeholder='Type or select an option' value={answers3} onChange={(e) => setAnswer3(e.target.value)} ></input>
                    <div>
                    <button className="question-button" onClick={() =>handleClick(2)}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button"  disabled={!answers3} onClick={() =>handleClick(4)}>OK</button> 
                    </div>
                </div> }
                {question[4] && <div className="questions">
                    <label>What do you hope to gain by participating in a psychedelic retreat?*</label><br></br>
                    <input className='form-control' placeholder='Type or select an option' value={answers4} onChange={(e) => setAnswer4(e.target.value)} ></input>
                    <div>
                    <button className="question-button" onClick={() =>handleClick(3)}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button"  disabled={!answers4} onClick={() =>handleClick(5)}>OK</button> 
                    </div>
                </div>}
                {question[5] && <div className="questions">
                    <label>Do you currently or have you ever suffered from drug and/or alcohol abuse (including, but not limited to: Morphine, ketamine, cocaine, heroine, amphetamines, GHB, opioids, painkillers and/or other prescription drugs)?</label><br></br>
                    <input className='form-control' placeholder='Type or select your country' value={answers5} onChange={(e) => setAnswer5(e.target.value)} required ></input>
                    <div>
                    <button className="question-button" onClick={() =>handleClick(4)}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button"  disabled={!answers5} onClick={() =>handleClick(6)}>OK</button> 
                    </div>
                </div>}
                {question[6] && <div className="questions">
                    <label>Have you ever been diagnosed with or have you ever suspected that you suffer from any of the conditions listed below?*</label><br></br>
                    <input className='form-control' placeholder='Type or select your country' value={answers6} onChange={(e) => setAnswer6(e.target.value)} ></input>
                    <div>
                    <button className="question-button" onClick={() =>handleClick(5)}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button"  disabled={!answers6} onClick={() =>handleClick(7)}>OK</button> 
                    </div>
                </div>}
                {question[7] &&<div className="questions">
                    <label>Within the last five years have you experienced psychiatric hospitalization?</label><br></br>
                    <input className='form-control' placeholder='Type or select your country' value={answers7} onChange={(e) => setAnswer7(e.target.value)} ></input>
                    <div>
                    <button className="question-button" onClick={() =>handleClick(6)}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button" onClick={() =>handleClick(8)}>OK</button> 
                    </div>
                </div>}
                {question[8] && <div className="questions">
                    <label>Are you currently taking or within the last 12-months have you taken the following medications?This question is required. *</label><br></br>
                    <input className='form-control' placeholder='Type or select your country' value={answers8} onChange={(e) => setAnswer8(e.target.value)} ></input>
                    <div>
                    <button className="question-button" onClick={() =>handleClick(7)}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button" >Submit</button> 
                    </div>
                </div>} */}
                 {/*<div className="questions">
                    <label>What country do you currently live in?</label><br></br>
                    <input className='form-control' placeholder='Type or select your country' required ></input>
                </div>
                <div className="questions">
                    <label>What country do you currently live in?</label><br></br>
                    <input className='form-control' placeholder='Type or select your country' required ></input>
                </div> */}

                // function getData() {
    
                //     var params = {
                //     Bucket: "synthesis-customer-portal-data",
                //        Key: "step1-form.json"
                //        };
                //     AWS.config.update({accessKeyId: "AKIAY7INHIQKSA6CFJHC",
                //     secretAccessKey:"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",
                //     region: "eu-central-1"})
                //     const s3 = new AWS.S3()
                //     s3.getObject(params, function(err, data) {
                //         if (err) console.log(err, err.stack); // an error occurred
                //         else{ return data,    console.log(data)}; });
                   
                //   }

                // const changeTheInputFieldColor=() =>{
//     console.log('hey')
//   //console.log(document.getElementById('question1').Style.border ='red');
//   let elms = document.getElementsByClassName("form-control");
//     console.log("elements deeps",elms.length, +"      "+elms);
//       for(var i = 0; i < elms.length; i++) {
//         if(!answers1){
//          console.log(elms[i].getAttribute("style"));   
//        (elms[i].setAttribute("style","border-bottom-color:red"));
//     return true}
//        else{
//        (elms[i].setAttribute("style","border-bottom-color:#ccc"));
//     }

//       }
// }
// const handleChange = ({ target: { name, value } }) => {
//     console.log(name)
//     console.log(value)
//     setInputValues({
//       ...inputValues,
//       [name]: value
//     })
    
//   }