import React from 'react'
import { useState } from 'react'
import Demographics from './Demographics'
/* eslint-disable */
const Healthscreener =() =>{
    const [checked,setChecked] = useState(false)
    const [welcome,setwelcome] = useState(true)
    const [checked1,setchecked1] = useState(false)
    const [checked2,setchecked2] = useState(false)
    const [welcomeAdditionInfo,setwelcomeAdditionaInfo] = useState(false)
    const [HealthQuestion,setHealthQuestioner] = useState(false);
    return(
        <div className='welcome-content'>
           {welcome && <div> 
                <h2>Synthesis Health Screening</h2><br/>
                <h3>Safety &#38; Data </h3> <br/>
                <p>Your Safety</p>
                <p>To ensure your safety, it is essential that we have accurate information regarding your physical and mental health.</p>
                <p>Failure to disclose any medical information will result in Synthesis withholding participation in the psilocybin-containing truffle ceremony without refund. </p>
                <p>Your safety is our highest priority. By accepting, I certify that all information is true and correct to the best of my knowledge.</p><br/>
                    <div>
                        <input type="checkbox" id="agree" name="agree"  checked={checked} onClick={()=>{setChecked(true)}}/>&nbsp;
                        <label for="agree">I agree*</label>
                    </div><br/>
                    {checked && <button className="start" onClick={()=>{(setwelcome(false), setwelcomeAdditionaInfo(true))}} >Next</button>}
            </div>}
                {welcomeAdditionInfo && <div>
                    <h2>Synthesis Health Screening</h2><br/>
                    <h3>Your Data</h3><br/>
                    <p>In this screening questionnaire, and further on during your journey with Synthesis, you will be asked to provide sensitive personal information.</p><br/>
                    <p>Any data you provide through this Health Screener, your Exploration Call, your 1:1 conversations may be stored, processed and analyzed by Synthesis for the following purposes: Health Screening This step is of vital importance as it enables us to advise you whether a psychedelic retreat could be a wholesome experience for you, and to offer you the best possible care when you come on retreat.</p><br/>
                    <h3>Support your wellbeing</h3><br/>
                    <p>We may review your personal information before, during and after your retreat in order to support your physical and psychological well-being.</p><br/>
                    <h3>Synthesis Service Improvement</h3><br/>
                    <p>We may analyze and use your data internally to improve the quality of our programs and services, as well as to deepen our understanding of psychedelic-assisted therapy and the efficacy of psychedelic retreats.</p><br/>
                    <p>Your data will be handled to the highest standards of confidentiality.</p><br/>
                    <a href="https://explore.synthesisretreat.com/data-safety" target="_blank" >Data Safety at Synthesis Explained</a><br></br>
                    <p>Click here to learn more on why, where and how we process your data.</p><br/>
                    <div>
                        <input type="checkbox" id="agree" name="agree" checked={checked1} onClick={()=>{setchecked1(true)}}/>&nbsp;
                        <label for="agree">I accept that all data I provide through this Health Screener, my Exploration Call, my 1:1 conversations, may be stored, processed and analyzed by Synthesis for the above mentioned purposes.</label> 
                    </div><br/>
                    <div>
                        <input type="checkbox" id="agree" name="agree" checked={checked2} onClick={()=>{setchecked2(true)}}/>&nbsp;
                        <label for="agree"> I understand that if I commit to participate in a retreat program, until I’ve completed the program, I cannot opt out from data collection by Synthesis. Once I’ve completed my journey with Synthesis I am able to request for all my data to be removed.</label> 
                    </div><br/>
                   { checked1 && checked2 && <div><button className="start" onClick={()=>{setHealthQuestioner(true),setwelcomeAdditionaInfo(false)}}>Next</button><br/></div> }
                   </div> 
                }
                {HealthQuestion && <Demographics/>}
        </div>
    )
}
export default Healthscreener