import React, { useEffect, useSate } from 'react';

const PaymentDealupdate = (email) =>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: email
    };
    console.log(requestOptions);
     useEffect(() => {
      fetch('https://backend.journey.synthesisretreat.com/api/payment-deal-update', requestOptions)
      .then(response => response.json())
          .catch(e => {
            console.log(e);
          });  
         },[]);
          return null 
}
export default PaymentDealupdate;
