import React from 'react'
import SendDataToBackend from './SendDataToBackend'
import Steps from './Steps';
import { useState } from 'react';

const NotQualified =(inputValues)=>{
    console.log("called it!!!",inputValues);
    <SendDataToBackend parentToChild={(inputValues)}/>
    let [dealId,setDealID] = useState("8124510");
    let [autoScreen,setAutoScreen] = useState("1");
 return (
    <div>
        <Steps dealId={dealId} autoScreen={autoScreen}/>
        <h2>Thank you for completing the Retreat Enrollment Questionnaire</h2><br></br>
        <p>We regret to inform you that based on the answers you provided, we’re unable to support your participation in a psychedelic retreat at this time.</p><br></br>
        <p>At Synthesis, safety is our number one priority. Although the retreat experience can be beneficial for many, for some it’s not appropriate to consume truffles containing psilocybin. These include people who have medical contraindications relating to their physical and mental health.</p><br></br>
        <p>Our application process aims to mitigate the risk for our participants before, during and after a retreat and although it may be disappointing, we believe the decision to decline your participation in a retreat prioritizes your health and safety.</p><br></br>
        <p>If you have additional questions, please feel free to contact us at support@synthesisretreat.com.</p><br></br>
        <p>Warmly,</p><br></br>
        <p>The Synthesis Retreat Support Team</p><br></br>
    </div>
 )
}
export default NotQualified