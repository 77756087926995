import { useState } from "react";
import Payment from "./Payment";
import Steps from "./Steps";
/* eslint-disable */
const AutoScreen = () =>{
    const [payment,setPaymentpage] = useState(true);
    const [callStripe,setCallStripr] = useState(false);
    const [welcomePaymentpage,setWelcomepage] = useState(false)
    const [checked,setChecked] = useState(false)
    //Steps("8124510");
    let [dealId,setDealID] = useState("8124510");
    let [autoScreen,setAutoScreen] = useState("0");
    return(
        <div>
            <Steps dealId = {dealId} autoScreen={autoScreen} />
        {payment && <div className="payment-content">
        <h3>Thank you for completing the Retreat Enrollment Questionnaire</h3><br></br>
        <p>We’re pleased to inform you that based on the answers provided, you’re eligible to proceed to step two of the application process.</p><br></br>
        <p>As a reminder, step two of the application process is Health Screening and requires that you:</p><br></br>
        <p>Submit the $1 retreat application fee*; Answer 75+ questions relating to your medical history, overall health and relevant personal experiences.</p><br></br>
        <p>*This fee will be applied to your retreat tuition once your application is approved. If your application is declined, the fee will be fully refunded.</p><br></br>
        <p>Are you ready to begin step two of the retreat application process? Simply click the link below to get started.</p><br></br>
        <button class="start" onClick={() =>{setWelcomepage(true),setPaymentpage(false)}}>Continue to step 2</button></div>}
    {welcomePaymentpage && <div className="payment-content">
        <h3>Retreat Application Process</h3>
        <h3>Step Two: Health Screening Application Fee</h3><br></br>
        <ul className="points">
        <h3> $200 retreat application fee is required from all applicants. This fee is refundable according to the following terms and conditions:</h3>
            <li>When an applicant is fully approved to participate in a psychedelic retreat, the $200 retreat application fee will be deducted from the retreat tuition total.</li>
            <li>If an applicant is not approved to participate in a psychedelic retreat, the $200 retreat application fee will be refunded.</li>
        </ul><br></br>
        <ul className="points">
        <h3>The application fee is not refundable if:</h3>
            <li>An applicant does not complete and submit their Health Screening.</li>
            <li>An applicant does not attend their pre-scheduled Assessment Session.</li>
            <li>An applicant is found to have provided untruthful or misleading information at any time during the retreat application process.</li>
        </ul><br></br>
        <h3>I agree to the terms and conditions of the $200 retreat application fee as outlined above.</h3><br/>
        <div>
        <input type="checkbox" id="agree" name="agree"  checked={checked} onClick={()=>{setChecked(true)}}/>&nbsp;
        <label htmlFor="agree">I agree*</label>
        </div>
        {checked && <button className="start" onClick={()=>{setCallStripr(true)}}>CLICK HERE TO PAY THE RETREAT APPLICATION FEE</button>}
    </div>
    }
    {callStripe && <Payment/>}
    </div>
    )

}
export default AutoScreen;