import React, { useState } from 'react'
import HealthScreenThankyouPage from './HealthScreenThankyouPage';
import { useAuth0 } from '@auth0/auth0-react';

/* eslint-disable */
const Demographics =() =>{
    const {user} = useAuth0();
    const[question,setquestions] = useState({1:true});
    const [Thankyoupage,setThankyoupage] = useState(false);
    const [Demographic,setDemographic] = useState(false);
    const [healthscreenerDataObj, setHealthScreenerDataObj] = useState({})
    const [errorMsg,setErrorMsg] = useState(false);
    const [submitHandle,setSubmitHandle] = useState(false);
    const [submitionPage,setSubmitionPage] = useState(false);
    const [underAge,setUnderAge] = useState(false);
    const [sendToUnderAgePage,setSendToUnderAgePage] = useState(false);
    const handleHealthClick =(event,nextQuestionnumber,currentquesitonnumner,value) =>{
        console.log(value);
        if(value=== '' || !value)
        {
            setquestions({[nextQuestionnumber]: false})
            setErrorMsg(true)
            setquestions({[currentquesitonnumner]: true});
            
        }else{
        for(var i=1;i<=nextQuestionnumber;i++){
            if(i===nextQuestionnumber){
                setquestions({[i]:true})
            }
            else
            setquestions({[i]:false})
        }
        }
    } 
    const birthdayValidation = (value) =>{
        var bday=value;
        bday=bday.split("-");
        var bday_in_milliseconds = new Date(parseInt(bday[0], 10), parseInt(bday[1], 10) - 1 , parseInt(bday[2]), 10).getTime(); //birth-date in milliseconds
        var now = new Date().getTime(); //current time in milliseconds
        if(now - bday_in_milliseconds > 567648000000){ 
            console.log("18+");
            setUnderAge(false);
        }
        else{
        console.log(" under 18")
        setUnderAge(true);
        }
    } 
    const handleHealthScreenData =(name,value) =>{
        console.log(name,value)
        if(name === "question1"){
        birthdayValidation(value);
        }
        setErrorMsg(false);
        setHealthScreenerDataObj({
            ...healthscreenerDataObj,
            [name]: value
          })

    }
    const handleSubmit = (q6,q7,q8,q9,q10,q11,q12,q13,q14) =>{
        if(!q6 || !q7 || !q8 || !q9 || !q10 || !q11 || !q12 || !q13 || !q14){
            setSubmitHandle(true)
        }else{
            setSubmitionPage(true)
            setThankyoupage(true)
        }
    }
    return(
        <div>
           { !Thankyoupage && <div><h2>Synthesis Health Screening</h2><br/></div>}
            { !Demographic && <div><h3>Demographics</h3><br/> </div>}
            {! submitionPage && 
            <div className="healthscreen-demographics">
               {/* {question[1] && <div>
                <label>1 .  To ensure that we keep your details in one place, please confirm the email that you signed up with. *</label><br/>
                <input className='healthsreen-form' autoFocus value={healthscreenerDataObj["question1"] = user.email} onChange={(e)=>{handleHealthScreenData("question1",e.target.value)}} onKeyDown={(event)=>{event.key === 'Enter' ? handleHealthClick(event,2,1,healthscreenerDataObj["question1"]): ' '}} placeholder='Please enter your email' ></input> <br></br>
                <div><br/>
                <div>
                    <input type="checkbox" id="agree" name="agree"/>&nbsp;
                    <label htmlFor="agree"> I confirm this is the same email address I used when I booked my exploration call</label> 
                    </div>
                   <button className="question-button" onClick={(event)=>handleHealthClick(event,2,1,healthscreenerDataObj["question1"])}>Next</button> <br/>
                   {errorMsg && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                </div>} */}
                {question[1] && 
                <div>
                    <label>1 . What is your date of birth? *</label><br/>
                    <input className='healthsreen-form' autoFocus type='date' value={healthscreenerDataObj["question1"]} onChange={(e)=>{handleHealthScreenData("question1",e.target.value)}} onKeyDown={(event)=>{(event.key === 'Enter' && underAge === false)? handleHealthClick(event,2,1,healthscreenerDataObj["question1"]) : ' '}} ></input>
                    <div>
                    {/* <button className="question-button" onClick={(event) =>handleHealthClick(event,1,2,healthscreenerDataObj["question1"])}>Back</button> 
                    <div className="divider"/> */}
                    {<button className="question-button" disabled={underAge} onClick={(event)=>handleHealthClick(event,2,1,healthscreenerDataObj["question1"])}>Next</button>}
                    {errorMsg && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                    {underAge && <div>
                        <span style={{color: "red"}}>
                            Sorry your're underAge to continue the healthscreener you must be 18+
                        </span>
                    </div>}
                    </div>
                </div>
                }
                {question[2] &&
                <div>  
                    <label>2 .  What is your gender? *</label><br/>
                    <div>
                    <input type="checkbox" autoFocus id="Male" name="Male" value="Male" checked={healthscreenerDataObj["question2"] === "Male"} onChange={(e)=>{handleHealthScreenData("question2",e.target.value)}} onKeyDown={(event)=>{event.key === 'Enter' ? handleHealthClick(event,3,2,healthscreenerDataObj["question2"]) : ' '}} />&nbsp;
                    <label htmlFor="Male">Male</label>
                    </div>
                    <div>
                    <input type="checkbox" autoFocus id="Female" name="Female" value="Female" checked={healthscreenerDataObj["question2"] === "Female"} onChange={(e)=>{handleHealthScreenData("question2",e.target.value)}} onKeyDown={(event)=>{event.key === 'Enter' ? handleHealthClick(event,3,2,healthscreenerDataObj["question2"]) : ' '}} />&nbsp;
                    <label htmlFor="Female">Female</label>
                    </div>
                    <div>
                    <input type="checkbox" autoFocus id="Selfdescribe" name="Selfdescribe" value="Selfdescribe" checked={healthscreenerDataObj["question2"] === "Selfdescribe"}  onChange={(e)=>{handleHealthScreenData("question2",e.target.value)}} onKeyDown={(event)=>{event.key === 'Enter' ? handleHealthClick(event,3,2,healthscreenerDataObj["question2"]) : ' '}} />&nbsp;
                    <label htmlFor="Selfdescribe">Self describe</label>
                    </div>
                    <div>
                    <button className="question-button" onClick={(event) =>handleHealthClick(event,1,2,healthscreenerDataObj["question1"])}>Back</button> 
                    <div className="divider"/>
                    {/* <button className="question-button"  onClick={() =>{handleHealthClick(3),setThankyoupage(true)}}>Next</button>  */}
                    <button className="question-button" onClick={(event)=>handleHealthClick(event,3,2,healthscreenerDataObj["question2"])}>Next</button> <br/>
                    {errorMsg && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                    </div>
                </div>
                }
                {question[3] &&
                <div>  
                    <label>3 .  Are you pregnant, planning to become pregnant and/or breastfeeding? *</label><br/>
                    <div>
                    <input type="checkbox" id="Yes" name="Yes" value="Yes" checked={healthscreenerDataObj["question3"] === "Yes"}  onChange={(e)=>{handleHealthScreenData("question3",e.target.value)}} onKeyDown={(event)=>{event.key === 'Enter' ? handleHealthClick(event,4,3,healthscreenerDataObj["question3"]) : ' '}}/>&nbsp;
                    <label htmlFor="Yes">Yes</label>
                    </div>
                    <div>
                    <input type="checkbox" id="No" name="No" value="No" checked={healthscreenerDataObj["question3"] === "No"} onChange={(e)=>{handleHealthScreenData("question3",e.target.value)}} onKeyDown={(event)=>{event.key === 'Enter' ? handleHealthClick(event,4,3,healthscreenerDataObj["question3"]) : ' '}}/>&nbsp;
                    <label htmlFor="No">No</label>
                    </div>
                    <div>
                    <button className="question-button" onClick={(event) =>handleHealthClick(event,2,3,healthscreenerDataObj["question2"])}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button" onClick={(event)=>handleHealthClick(event,4,3,healthscreenerDataObj["question3"])}>Next</button> <br/>
                    {errorMsg && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                    </div>
                </div>
                }
                {question[4] && <div>
                <label>4 .  Have you ever used a psychedelic before (ayahuasca, mescaline, LSD, etc.)? *</label><br/>
                <div>
                <input type="button" value="Yes" style={(healthscreenerDataObj["question4"] === "Yes")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question4","Yes")}} onKeyDown={(event)=>{event.key === 'Enter' ? (handleHealthClick(event,5,4,healthscreenerDataObj["question4"]),setDemographic(true)) : ' '}}/>&nbsp;
               <input type="button" value="No" style={(healthscreenerDataObj["question4"] === "No")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question4","No")}} onKeyDown={(event)=>{event.key === 'Enter' ? (handleHealthClick(event,5,4,healthscreenerDataObj["question4"]),setDemographic(true)) : ' '}}/>
                </div>
                <div>
                    <button className="question-button" onClick={(event) =>handleHealthClick(event,3,4,healthscreenerDataObj["question3"])}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button" onClick={(event)=> {handleHealthClick(event,5,4,healthscreenerDataObj["question4"]),setDemographic(true)}}>Next</button> <br/>
                    {errorMsg && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                    </div>
                </div>}
                {question[5] && <div>
                    <p>To ensure that the experience we are providing is of the highest quality we would like to gather some basic information from you. Most studies have shown psilocybin to be low risk, with a low potential for abuse, and generally well-tolerated in healthy individuals. Due to the variability and unpredictability of effects however, taking psilocybin truffles always comes with some risks even in the same individual at different times. We have designed the following questions to assess any extra risk that may pertain to you.</p>
                    <div>
                    <button className="question-button" onClick={(event) =>handleHealthClick(event,4,5,healthscreenerDataObj["question4"])}>Back</button> 
                    <div className="divider"/>
                    <button className="question-button" onClick={(event)=> {handleHealthClick(event,6,5,"moveto7"),setDemographic(true)}}>Next</button> <br/>
                    </div>
                </div>
                }
                {question[6] && 
                <div className='assessment'>
                    <h3>Assessment</h3><br/>
                    <p>The following set of questions will give us a better understanding of your current situation.</p>
                    <div><p style={{textDecorationLine: "underline"}}>Over the last 2 weeks</p> how often have you been bothered by any of the following:</div><br/>

                    <div>
                    <label>6 .  Little interest or pleasure in doing things? *</label><br/>
                        <div>
                        <input type="button" value="Not at all" style={(healthscreenerDataObj["question6"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question6","Not at all")}}/>&nbsp;
                        <input type="button" value="Several days" style={(healthscreenerDataObj["question6"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question6","Several days")}}/>
                        <input type="button" value="More than half the days" style={(healthscreenerDataObj["question6"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question6","More than half the days")}}/>
                        <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question6"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question6","Nearly every day")}}/>
                        </div>
                    <div>
                        {submitHandle && !healthscreenerDataObj["question6"] && <div>
                        <span style={{color: "red"}}>Please answer the question </span>
                        </div>}
                        </div>
                    </div><br/>
                    <div>
                <label>7 .  Feeling down, depressed or hopeless? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question7"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question7","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question7"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question7","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question7"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question7","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question7"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question7","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question7"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                <label>8 .  Trouble falling asleep, staying asleep or sleeping too much? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question8"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question8","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question8"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question8","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question8"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question8","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question8"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question8","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question8"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                <label>9 .  Feeling tired or having little energy? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question9"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question9","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question9"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question9","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question9"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question9","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question9"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question9","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question9"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                <label>10 .  Poor appetite or overeating? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question10"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question10","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question10"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question10","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question10"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question10","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question10"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question10","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question10"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                <label>11 .  Feeling bad about yourself - or that you’re a failure or have let yourself or your family down? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question11"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question11","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question11"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question11","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question11"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question11","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question11"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question11","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question11"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                <label>12 .  Trouble concentrating on things such as reading the newspaper or watching television? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question12"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question12","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question12"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question12","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question12"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question12","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question12"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question12","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question12"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                <label>13 .  Moving or speaking so slowly that other people could have noticed. Or, the opposite - being so fidgety or restless that you have been moving around a lot more than usual? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question13"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question13","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question13"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question13","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question13"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question13","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question13"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question13","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question13"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                <label>14 .  Thoughts that you would be better off dead or of hurting yourself in some way? *</label><br/>
                <div>
                <input type="button" value="Not at all" style={(healthscreenerDataObj["question14"] === "Not at all")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"}}  onClick={(e)=>{handleHealthScreenData("question14","Not at all")}}/>&nbsp;
               <input type="button" value="Several days" style={(healthscreenerDataObj["question14"] === "Several days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question14","Several days")}}/>
               <input type="button" value="More than half the days" style={(healthscreenerDataObj["question14"] === "More than half the days")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question14","More than half the days")}}/>
               <input type="button" value="Nearly every day" style={(healthscreenerDataObj["question14"] === "Nearly every day")? {backgroundColor:"#0d1d42"} : {backgroundColor:"#eff1f5"} }  onClick={(e)=>{handleHealthScreenData("question14","Nearly every day")}}/>
                </div>
                <div>
                    {submitHandle && !healthscreenerDataObj["question14"] && <div>
                       <span style={{color: "red"}}>Please answer the question </span>
                       </div>}
                </div>
                    </div><br/>
                    <div>
                    <input type="button" value="Back" onClick={(event) =>handleHealthClick(event,6,7,"gobackto6")}/>&nbsp;
                    <input type="button" value="Submit" onClick={(e) =>{handleSubmit(healthscreenerDataObj["question6"],healthscreenerDataObj["question7"],healthscreenerDataObj["question8"],healthscreenerDataObj["question9"],healthscreenerDataObj["question10"],healthscreenerDataObj["question11"],healthscreenerDataObj["question12"],healthscreenerDataObj["question13"],healthscreenerDataObj["question14"])}}/> 
                    </div><br/>
                    </div>}
                <br/><br/>
            </div>}
            <div>
                {sendToUnderAgePage && <UnderAge/>}
            </div>
            <div>
                {Thankyoupage && <HealthScreenThankyouPage/>}
            </div>
        </div>
    )
}
export default Demographics