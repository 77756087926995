import React from 'react'
import { useState,useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
/* eslint-disable */
const Steps = ({dealId, autoScreen})=>{ 
    console.log("deepa"+dealId);
    console.log("autoScreen"+autoScreen)
     const [steps,setSteps] = useState({});
     ((dealId ==="8117338")? (steps[1]=true): (dealId === "8124510") ?  ((steps[1]=true),(steps[2]=true)) : (dealId === "8124511") ? ((steps[1]=true),(steps[2]=true),(steps[3]=true)) : (steps[1]=true))
    return(
    <div className="progress-bar">
        <div className= {steps[1] ? "step step-active" : "step" }>
            <div>
                <div className= "circle">1</div>
            </div>
            <div>
                <div className="title">First Step</div>
                <div className="caption">Questionnaire</div>
            </div>
        </div>
        <div className= { !autoScreen ? "step" :(steps[2] && autoScreen !== "1") ? "step step-active" : "step step-deactive" }>
            <div>
                <div className="circle">2</div>
            </div>
            <div>
                <div className="title">Second Step</div>
                <div className="caption">Auto Screening Results</div>
            </div>
        </div>
        <div className={steps[3] ? "step step-active" : "step" }>
            <div>
                <div className="circle">3</div>
            </div>
            <div>
                <div className="title">Third Step</div>
                <div className="caption">Payment</div>
            </div>
        </div>
                  
        <div className={steps[4] ? "step step-active" : "step" }>
            <div>
                <div className="circle">4</div>
            </div>
            <div>
                <div className="title">Fourth Step</div>
                <div className="caption">Health Screener</div>
            </div>
        </div>
    </div>
);
}
export default Steps;

