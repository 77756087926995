const DealCreation = (email) =>{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: email
    };
    console.log(requestOptions);
      fetch('https://backend.journey.synthesisretreat.com/api/deal-creation', requestOptions)
      .then(response => response.json())
          .catch(e => {
            console.log(e);
          });  
          return null
}
export default DealCreation